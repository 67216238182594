import {Routes} from '@angular/router';
import {authGuard} from '@core/guards';
import {authRoutes} from '@pages/auth/auth.routes';
import {homeRoutes} from '@pages/home/home.routes';
import {liveStreamRoutes} from '@pages/live-stream/live-stream.routes';
import {leaderboardRoutes} from '@pages/leaderboard/leaderboard.routes';
import {prodGuard} from '@core/guards/prod/prod-guard';

export const routes: Routes = [
    // {
    //     path: 'access',
    //     loadComponent: async () => (await import('@pages/access/access.component')).AccessComponent,
    // },
    {
        path: 'auth',
        loadChildren: async () => authRoutes,
        canMatch: [authGuard({requiresAuthentication: false, permissions: []})],
    },
    {
        path: '',
        loadChildren: async () => homeRoutes,
        canMatch: [authGuard({requiresAuthentication: false, permissions: []})],
    },
    {
        path: 'live/:id',
        loadChildren: async () => liveStreamRoutes,
        canMatch: [authGuard({requiresAuthentication: false, permissions: []})],
    },
    {
        path: 'leaderboard',
        loadChildren: async () => leaderboardRoutes,
        canMatch: [authGuard({requiresAuthentication: false, permissions: []}), prodGuard()],
    },
    {
        path: 'test-future',
        loadComponent: async () => (await import('@pages/test-future/test-future.component')).TestFutureComponent,
    },
    // {
    //     path: 'games/plinko',
    //     loadComponent: async () =>
    //         (await import('@shared/components/games/plinko-game/plinko-game.component')).PlinkoGameComponent,
    // },
    {
        path: '**',
        loadComponent: async () => (await import('@pages/not-found/not-found.component')).NotFoundComponent,
    },
];
