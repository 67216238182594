import {HttpErrorResponse, HttpInterceptorFn, HttpStatusCode} from '@angular/common/http';
import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services';
import {catchError, switchMap, throwError} from 'rxjs';
import {UserResponse} from '@core/models/user/user-response';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
    const router = inject(Router);
    const authService = inject(AuthService);
    let isRefreshing = false;

    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            if ([HttpStatusCode.Forbidden].includes(error.status)) {
                authService.logout();
                router.navigateByUrl('/');
            } else if ([HttpStatusCode.Unauthorized].includes(error.status)) {
                authService.isAuthenticated$.next(false);

                if (!isRefreshing) {
                    isRefreshing = true;

                    if (authService.appSession$.getValue() === null) {
                        authService.logout();
                        router.navigateByUrl('/');

                        return throwError(() => error);
                    }
                    return authService.refreshToken(authService.appSession$.getValue()!.refreshToken).pipe(
                        switchMap((data: UserResponse) => {
                            isRefreshing = false;
                            authService.setSession(
                                authService.appSession$.getValue()!.uuid,
                                authService.appSession$.getValue()!.isSkipped ? true : false,
                                data,
                            );
                            req = req.clone({
                                setHeaders: {Authorization: `Bearer ${data.token}`},
                            });
                            return next(req);
                        }),
                        catchError(error => {
                            isRefreshing = false;
                            if (error.status == '403') {
                                authService.logout();
                                router.navigateByUrl('/');
                            }

                            return throwError(() => error);
                        }),
                    );
                }

                return next(req);
            }

            return throwError(() => error);
        }),
    );
};
