import {Injectable, inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StatusResponse} from '@core/models/app/status-response';
import {environment} from '@env/environment';
import {BehaviorSubject} from 'rxjs';
import {storage} from '@core/utils';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    http = inject(HttpClient);

    serverSession$ = new BehaviorSubject(storage.getItem('serverSession'));
    constructor() {}

    get localizationFileUrl(): string {
        return this.serverSession$.getValue()!.localizationFileUrl;
    }

    getAppStatus() {
        return this.http.get<StatusResponse>(environment.apiUrl + '/status');
    }

    setServerSession(serverSession: {localizationFileUrl: string; isLive: boolean}) {
        this.serverSession$.next(serverSession);
        storage.setItem('serverSession', serverSession);
    }
}
