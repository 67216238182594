import {Routes} from '@angular/router';

export const homeRoutes: Routes = [
    {
        path: '',
        title: 'Home',
        loadComponent: async () => (await import('./home.component')).HomeComponent,
        children: [
            {
                path: '',
                redirectTo: 'live',
                pathMatch: 'full',
            },
            {
                path: 'following',
                title: 'Following',
                loadComponent: async () => (await import('./following/following.component')).FollowingComponent,
            },
            {
                path: 'live',
                title: 'Live',
                loadComponent: async () => (await import('./live/live.component')).LiveComponent,
            },
        ],
    },
];
