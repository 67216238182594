import { Routes } from '@angular/router';

export const authRoutes: Routes = [
  {
    path: 'login',
    title: 'Login',
    loadComponent: async () =>
      (await import('./login/login.component')).LoginComponent,
  },
  {
    path: 'register',
    title: 'Register',
    loadComponent: async () =>
      (await import('./register/register.component')).RegisterComponent,
  },
];
