<!--<main class="bg-white dark:bg-[#141414] w-full max-w-full h-auto min-h-full">
    <app-header></app-header>
    <section>
        <router-outlet></router-outlet>
    </section>
</main>-->
<router-outlet *ngIf="authState"></router-outlet>
<!--<app-live-stream></app-live-stream>-->
<div *ngIf="multipleLoginWarning" class="flex absolute left-0 top-0 w-full h-full z-50" style="background-color: black; opacity: 0.8;">
  <div class="m-auto text-white">
    <p>{{'Login_another_device_warning' | transloco }}</p>
  </div>
</div>
