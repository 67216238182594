import {HttpInterceptorFn} from '@angular/common/http';

export const headerInterceptor: HttpInterceptorFn = (req, next) => {
    const lang = navigator.language.split('-')[0];
    const clonedRequest = req.clone({
        setHeaders: {
            'Access-Control-Allow-Origin': '*',
            'x-req-langcode': lang,
            'x-req-platform': '2',
            'x-req-jigle' : '1'
        },
    });

    return next(clonedRequest);
};
