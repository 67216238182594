import {HttpInterceptorFn} from '@angular/common/http';
import {AuthService} from '../../services';
import {inject} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {storage} from '@core/utils';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
    const authService = inject(AuthService);
    const isRequestAuthorized = authService.isAuthenticated && req.url.startsWith(environment.apiUrl);

    const token = storage.getItem('appSession')?.token;

    if (isRequestAuthorized && token) {
        const clonedRequest = req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });

        return next(clonedRequest);
    }

    return next(req);
};
