import {ApplicationConfig, ErrorHandler, importProvidersFrom, inject, isDevMode} from '@angular/core';
import {provideRouter, withComponentInputBinding} from '@angular/router';
import {routes} from './app.routes';
import {HttpBackend, HttpClient, provideHttpClient, withFetch, withInterceptors} from '@angular/common/http';
import {errorInterceptor, tokenInterceptor} from './core/interceptors';
import {TranslocoHttpLoader} from './transloco-loader';
import {provideTransloco} from '@ngneat/transloco';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig} from '@abacritt/angularx-social-login';
import {environment} from '@env/environment';
import {SocketIoModule, SocketIoConfig} from 'ngx-socket-io';
import {provideLottieOptions} from 'ngx-lottie';
import player from 'lottie-web';
import {nullPhotoInterceptor} from '@core/interceptors/null-photo/null-photo-interceptor';
import {aresInterceptor} from '@core/interceptors/ares/ares.interceptor';
import {headerInterceptor} from '@core/interceptors/header/header.interceptor';
import {provideNgIconLoader} from '@ng-icons/core';

const config: SocketIoConfig = {url: environment.socketUrl, options: {transports: ['websocket'], autoConnect: false}};

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withComponentInputBinding()),
        provideHttpClient(
            withInterceptors([
                headerInterceptor,
                errorInterceptor,
                tokenInterceptor,
                nullPhotoInterceptor,
                aresInterceptor,
            ]),
        ),
        provideHttpClient(withFetch()),
        provideLottieOptions({
            player: () => player,
        }),
        provideTransloco({
            config: {
                availableLangs: ['en'],
                defaultLang: 'en',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoHttpLoader,
        }),
        provideAnimationsAsync(),
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.googleClientId, {
                            oneTapEnabled: false,
                            prompt: 'consent',
                        }),
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebookAppId, {
                            initOptions: {
                                appId: environment.facebookAppId,
                                xfbml: true,
                                version: 'v21.0',
                            },
                        }),
                    },
                ],
                onerror: (err: unknown): void => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
        provideNgIconLoader(name => {
            const httpBackend = inject(HttpBackend);
            const http = new HttpClient(httpBackend);
            return http.get(`/assets/images/icons/${name}.svg`, {responseType: 'text'});
        }),
        importProvidersFrom(SocketIoModule.forRoot(config)),
    ],
};
