/* eslint-disable */
// @ts-nocheck
import {HttpInterceptorFn} from '@angular/common/http';
import {AuthService} from '../../services';
import {inject} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {storage} from '@core/utils';
import {MD5, HmacSHA256, enc} from 'crypto-js';
import URLParse from 'url-parse';

function getQueryParams(url: string): {path: string; query: string} {
    const parsedUrl = new URLParse(url);
    const path = parsedUrl.pathname || '';
    return {path, query: parsedUrl.query};
}

export const aresInterceptor: HttpInterceptorFn = (req, next) => {
    const _0x2a4489 = _0x4cfe;
    (function (_0x249100, _0xddc5f0) {
        const _0x41aa68 = _0x4cfe,
            _0x4dc1db = _0x249100();
        while (!![]) {
            try {
                const _0x29fb3b =
                    (parseInt(_0x41aa68(0x8a)) / 0x1) * (parseInt(_0x41aa68(0x94)) / 0x2) +
                    (-parseInt(_0x41aa68(0x8d)) / 0x3) * (parseInt(_0x41aa68(0x9f)) / 0x4) +
                    (parseInt(_0x41aa68(0x89)) / 0x5) * (parseInt(_0x41aa68(0x93)) / 0x6) +
                    parseInt(_0x41aa68(0x97)) / 0x7 +
                    (-parseInt(_0x41aa68(0x81)) / 0x8) * (-parseInt(_0x41aa68(0xa1)) / 0x9) +
                    (parseInt(_0x41aa68(0x8b)) / 0xa) * (-parseInt(_0x41aa68(0x85)) / 0xb) +
                    parseInt(_0x41aa68(0x9d)) / 0xc;
                if (_0x29fb3b === _0xddc5f0) break;
                else _0x4dc1db['push'](_0x4dc1db['shift']());
            } catch (_0x578c6e) {
                _0x4dc1db['push'](_0x4dc1db['shift']());
            }
        }
    })(_0x1adc, 0x8118c);
    function _0x4cfe(_0x57a5c0, _0x4dad70) {
        const _0x1adc5f = _0x1adc();
        return (
            (_0x4cfe = function (_0x4cfed3, _0x2ca118) {
                _0x4cfed3 = _0x4cfed3 - 0x81;
                let _0x1a8216 = _0x1adc5f[_0x4cfed3];
                return _0x1a8216;
            }),
            _0x4cfe(_0x57a5c0, _0x4dad70)
        );
    }
    const authService = inject(AuthService),
        isRequestAuthorized = req[_0x2a4489(0x90)][_0x2a4489(0x95)](environment[_0x2a4489(0x8f)]),
        ARES_ADD_ON_01 = _0x2a4489(0x91),
        ARES_ADD_ON_02 = 'f370f9d6-2b88-491c-a3a4-08aa48161083',
        ARES_SECRET_01 = _0x2a4489(0x86);
    function createHmac(_0x33f0a3, _0x533c45) {
        const _0xc78389 = _0x2a4489,
            _0x3225f3 = HmacSHA256(_0x533c45, _0x33f0a3);
        return _0x3225f3[_0xc78389(0x8c)](enc[_0xc78389(0x82)]);
    }
    function createHash(_0x5c2b0a) {
        const _0x46fa33 = _0x2a4489,
            _0x24c1a6 = MD5(_0x5c2b0a);
        return _0x24c1a6['toString'](enc[_0x46fa33(0x82)]);
    }
    const makeAresEncryptionHash = (_0x30a7cc, _0x536bb8, _0x1e3983, _0x5d43de) => {
        const _0x53eff6 = _0x2a4489,
            _0x2acb2e = JSON['stringify'](_0x30a7cc),
            _0x132a7b = JSON[_0x53eff6(0xa2)](_0x536bb8),
            _0x5a69a0 = ARES_ADD_ON_01 + _0x1e3983 + _0x5d43de + _0x2acb2e + _0x132a7b + ARES_ADD_ON_02;
        return createHmac(ARES_SECRET_01, _0x5a69a0);
    };
    function hash(_0x46b873) {
        return createHash(_0x46b873);
    }
    function getBaseString(_0x5f3c35, _0x4284ae, _0x42d823) {
        const _0x43eb9e = _0x2a4489;
        let _0x46e386 = hash(_0x5f3c35);
        return (
            (_0x46e386 = _0x4284ae ? _0x46e386 + hash(_0x4284ae) : _0x46e386 + '0'[_0x43eb9e(0x98)](0x20)),
            (_0x46e386 = _0x42d823 ? _0x46e386 + hash(_0x42d823) : _0x46e386 + '0'['repeat'](0x20)),
            _0x46e386
        );
    }
    function getValue(_0xaee7c3, _0x455212, _0xb88677, _0x3602e3) {
        return encrypt(getBaseString(_0x455212, _0xb88677, _0x3602e3), _0xaee7c3);
    }
    function _0x1adc() {
        const _0x1ab564 = [
            '00000000',
            '10252416rWHtDH',
            'getValue',
            '626856JAKrWc',
            'slice',
            '1467eJGCVI',
            'stringify',
            '1208NTUHGk',
            'Hex',
            'now',
            'urlWithParams',
            '21549GGOpFY',
            'ares2130asmfmvai',
            'clone',
            'substring',
            '1240900bYTDHg',
            '32174XNPGBh',
            '2070lYAolh',
            'toString',
            '15NHSVAo',
            'appSession$',
            'apiUrl',
            'url',
            '86ce736fb0bf1b70885e95',
            'body',
            '6FbdWQv',
            '4dTsjxq',
            'startsWith',
            'push',
            '3684478AhjSPO',
            'repeat',
            'uuid',
            '0404b0d30000',
            'length',
        ];
        _0x1adc = function () {
            return _0x1ab564;
        };
        return _0x1adc();
    }
    function encrypt(_0x18fd73, _0x544c09) {
        const _0x5612ce = _0x2a4489,
            _0xf1f902 = 0x14,
            _0x185a67 = [
                0xdf, 0x77, 0xb9, 0x40, 0xb9, 0x9b, 0x84, 0x83, 0xd1, 0xb9, 0xcb, 0xd1, 0xf7, 0xc2, 0xb9, 0x85, 0xc3,
                0xd0, 0xfb, 0xc3,
            ],
            _0x128b56 = [];
        for (let _0x5f26f5 = 0x0; _0x5f26f5 < 0xc; _0x5f26f5 += 0x4) {
            const _0x4c0439 = _0x18fd73['slice'](0x8 * _0x5f26f5, 0x8 * (_0x5f26f5 + 0x1));
            for (let _0x160564 = 0x0; _0x160564 < 0x4; _0x160564++) {
                const _0x39e6c3 = parseInt(_0x4c0439[_0x5612ce(0xa0)](_0x160564 * 0x2, (_0x160564 + 0x1) * 0x2), 0x10);
                _0x128b56[_0x5612ce(0x96)](_0x39e6c3);
            }
        }
        _0x128b56[_0x5612ce(0x96)](0x0, 0x6, 0xb, 0x1c);
        const _0x3d09cb = (_0x5612ce(0x9c) + _0x544c09[_0x5612ce(0x8c)](0x10))[_0x5612ce(0xa0)](-0x8);
        _0x128b56[_0x5612ce(0x96)](
            parseInt(_0x3d09cb[_0x5612ce(0x88)](0x0, 0x2), 0x10),
            parseInt(_0x3d09cb['substring'](0x2, 0x4), 0x10),
            parseInt(_0x3d09cb['substring'](0x4, 0x6), 0x10),
            parseInt(_0x3d09cb[_0x5612ce(0x88)](0x6, 0x8), 0x10),
        );
        const _0x4cd9c0 = [];
        for (let _0x4cdbc6 = 0x0; _0x4cdbc6 < _0xf1f902; _0x4cdbc6++) {
            const _0x4b5834 = _0x128b56[_0x4cdbc6],
                _0x4de9da = _0x185a67[_0x4cdbc6];
            _0x4cd9c0[_0x5612ce(0x96)](_0x4b5834 ^ _0x4de9da);
        }
        for (let _0x5ca22d = 0x0; _0x5ca22d < _0xf1f902; _0x5ca22d++) {
            const _0x312bbe = reverse(_0x4cd9c0[_0x5ca22d]),
                _0x428aec = _0x4cd9c0[(_0x5ca22d + 0x1) % _0xf1f902],
                _0x506a5d = _0x312bbe ^ _0x428aec,
                _0x480a0a = rbitAlgorithm(_0x506a5d),
                _0xa30c7c = (_0x480a0a ^ 0xffffffff ^ _0xf1f902) & 0xff;
            _0x4cd9c0[_0x5ca22d] = _0xa30c7c;
        }
        let _0xdf1248 = '';
        for (const _0x1b5d37 of _0x4cd9c0) {
            _0xdf1248 += hexString(_0x1b5d37);
        }
        return _0x5612ce(0x9a) + _0xdf1248 + 'c1kc1k';
    }
    function rbitAlgorithm(_0x857ded) {
        const _0x5ac112 = _0x2a4489;
        let _0x34b7fa = '',
            _0x5d8994 = _0x857ded[_0x5ac112(0x8c)](0x2);
        while (_0x5d8994[_0x5ac112(0x9b)] < 0x8) {
            _0x5d8994 = '0' + _0x5d8994;
        }
        for (let _0xada856 = 0x0; _0xada856 < 0x8; _0xada856++) {
            _0x34b7fa = _0x34b7fa + _0x5d8994[0x7 - _0xada856];
        }
        return parseInt(_0x34b7fa, 0x2);
    }
    function hexString(_0x20dfc3) {
        let _0x30a8e4 = _0x20dfc3['toString'](0x10);
        return _0x30a8e4['length'] < 0x2 && (_0x30a8e4 = '0' + _0x30a8e4), _0x30a8e4;
    }
    function reverse(_0xdfff47) {
        const _0x22dfea = hexString(_0xdfff47);
        return parseInt(_0x22dfea[0x1] + _0x22dfea[0x0], 0x10);
    }
    const makeXigenonEncryptionHash = (_0x2f653f, _0x2e0325, _0x352a36, _0x537eec, _0x5d92e5) => {
            const _0x1059bc = _0x2a4489;
            return getValue(parseInt(_0x537eec), JSON[_0x1059bc(0xa2)](_0x2f653f, _0x2e0325), _0x5d92e5, _0x352a36);
        },
        xDiez = '9d1389db-39a2-4b76-b4e3-98fc2648901a',
        uuid = authService[_0x2a4489(0x8e)][_0x2a4489(0x9e)]()?.[_0x2a4489(0x99)] || '',
        timestamp = Date[_0x2a4489(0x83)]()['toString'](),
        xAresCheck = 'ok',
        {path, query} = getQueryParams(req[_0x2a4489(0x84)]),
        aresHash = makeAresEncryptionHash(query, req[_0x2a4489(0x92)], uuid, timestamp),
        xigenonHash = makeXigenonEncryptionHash(query, req[_0x2a4489(0x92)], uuid, timestamp, path),
        clonedRequest = req[_0x2a4489(0x87)]({
            setHeaders: {
                'x-ares-check': xAresCheck,
                'x-cypher': timestamp,
                'x-ares': aresHash,
                uuid: uuid,
                'x-diez': xDiez,
                'x-xigenon': xigenonHash,
            },
        });
    return next(clonedRequest);
};
