import {inject} from '@angular/core';
import {CanMatchFn, Route, Router, UrlSegment} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {AuthGuardOptions} from './auth.types';

const defaultAuthGuardOptions = (): AuthGuardOptions => ({
    requiresAuthentication: true,
    permissions: [],
});

export const authGuard = (options: AuthGuardOptions = defaultAuthGuardOptions()): CanMatchFn => {
    return () => {
        const router = inject(Router);
        const authService = inject(AuthService);

        return true;
        //     if (authService.canProceed) return true;
        //     //temp check, this control will be change when auth services ready
        //     if (options.requiresAuthentication === authService.canProceed) {
        //         return true;
        //     }
        //     //const authService = inject();
        //
        //     return options.requiresAuthentication
        //         ? router.createUrlTree(['/access'])
        //         : router.createUrlTree(['/auth/login']);
    };
};
