import {Component, HostBinding, inject, OnInit} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {HeaderComponent} from '@core/components/header/header.component';
import {AuthService, ThemeService} from '@core/services';
import {LoginComponent} from '@pages/auth/login/login.component';
import {QrLoginComponent} from '@pages/auth/qr-login/qr-login.component';
import {RegisterComponent} from '@pages/auth/register/register.component';
import {MailLoginModalComponent} from '@pages/auth/mail-login-modal/mail-login-modal.component';
import {ForgotPasswordModalComponent} from '@pages/auth/forgot-password-modal/forgot-password-modal.component';
import {LiveStreamComponent} from '@pages/live-stream/live-stream.component';
import {ChatSocketService} from '@core/services/chat/chat-socket.service';
import {UserResponse} from '@core/models/user/user-response';
import {CommonModule} from '@angular/common';
import {UserService} from '@core/services/user/user.service';
import {ConfigResponse} from '@core/models/user/config-response';
import {TranslocoPipe, TranslocoService} from '@ngneat/transloco';
import {UserProfileResponse} from '@core/models/user/user-profile-response';
import * as amplitude from '@amplitude/analytics-browser';
import {environment} from '@env/environment';
import {LivePartyListSocket} from '@core/models/chat/socket/live-party-list-socket';
import {CandidateService} from '@core/services/candidate/candidate.service';
import {PartyDetailResponse} from '@core/models/candidate/party-detail-response';
import _ from 'lodash';
import {AppService} from '@core/services/app/app.service';
import {StatusResponse} from '@core/models/app/status-response';
import {ConversionService} from '@core/services/conversion/conversion.service';
//import {ApiCommandSocket} from '@core/models/chat/socket/api-comman-socket';
//import {IncomingApiCommandEvent} from '@core/enums/incoming-api-command-event.enum';
//import {AlertModalComponent} from '@shared/components/alert-modal/alert-modal.component';
//import {ModalService} from '@core/services/modal/modal.service';
//import {Subscription} from 'rxjs';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        HeaderComponent,
        LoginComponent,
        QrLoginComponent,
        RegisterComponent,
        MailLoginModalComponent,
        ForgotPasswordModalComponent,
        LiveStreamComponent,
        CommonModule,
        TranslocoPipe,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    title = 'cikcik-web';
    themeService = inject(ThemeService);

    appService = inject(AppService);
    authService = inject(AuthService);
    chatSocketService = inject(ChatSocketService);
    candidateService = inject(CandidateService);
    userService = inject(UserService);
    conversionService = inject(ConversionService);
    //    modalService = inject(ModalService);
    router = inject(Router);
    translocoService = inject(TranslocoService);
    authState: boolean = false;
    multipleLoginWarning: boolean = false;

    isAgent: boolean = false;
    //    socketApiCommandSub!: Subscription;

    constructor() {
        // DONT USE FOR NOW
        // const headElement = document.getElementsByTagName('head')[0];
        // const appId = environment.production ? '1521452500' : '6449460269';
        // const metaElement = document.createElement('meta');
        // metaElement.name = 'apple-itunes-app';
        // metaElement.content = 'app-id=' + appId;
        // headElement.insertAdjacentElement('beforeend', metaElement);
        this.appService.getAppStatus().subscribe((data: StatusResponse) => {
            const isLive = data.isLive;
            const localizationFileUrl = data.localizationFileUrl;
            this.appService.setServerSession({localizationFileUrl, isLive});
            this.translocoService.getTranslation('en');
        });
        this.authService.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
            if (!isAuthenticated) {
                if (this.chatSocketService.chatSocket) {
                    this.chatSocketService.disconnectSocket();
                }
                // register with random user
                this.authService.generateFingerprint().then(result => {
                    this.authService
                        .register({
                            countryCode: Intl.DateTimeFormat().resolvedOptions().locale,
                            langCode: navigator.language,
                            deviceType: 2,
                            isSkipped: '1',
                            uuid: result.visitorId,
                        })
                        .subscribe((data: UserResponse) => {
                            if (data.success) {
                                this.authService.setSession(result.visitorId, true, data);
                                window.location.reload();
                                /*this.updateConfig(
                                    Intl.DateTimeFormat().resolvedOptions().locale,
                                    navigator.language.split('-')[0],
                                );*/
                            }
                        });
                });
            } else {
                this.authService.appSession$.subscribe(data => {
                    if (data != null) {
                        if (this.authService.appSession$.getValue() != null) {
                            this.authState = true;
                            this.updateConfig(
                                this.authService.appSession$.getValue()!.userDetail.country.iso2Code,
                                navigator.language.split('-')[0],
                            );
                            this.isAgent = this.authService.appSession$.getValue()!.userDetail.userLevel != 0;
                            this.appService.serverSession$.subscribe(serverSession => {
                                if (serverSession != null)
                                    this.translocoService.getTranslation(serverSession.localizationFileUrl);
                                else this.translocoService.getTranslation('en');
                            });
                            const userMail = this.authService.userDetail.email;
                            const userId = this.authService.userId;
                            (window as any).twq('event', 'tw-omcbx-omvsr', {
                                conversion_id: userId,
                                email_address: userMail,
                            });
                        }
                    }
                });
            }
        });

        this.chatSocketService.isConnected$.subscribe((isconnected: boolean) => {
            if (isconnected) {
                // this.authService.kickAccount.subscribe((isKicked: boolean) => {
                //     if (isKicked) {
                //         this.multipleLoginWarning = true;
                //         setTimeout(() => {
                //             this.authService.logout();
                //         }, 3000);
                //     }
                // });

                this.chatSocketService.onLivePartyList().subscribe((data: string) => {
                    const resParties: LivePartyListSocket = JSON.parse(data);

                    resParties.parties = resParties.parties.filter(
                        el => !this.chatSocketService.bannedRooms.includes(el),
                    );

                    const uniqueRooms = resParties.parties.filter(
                        (value, index, array) => array.indexOf(value) === index,
                    );

                    this.updatePartyList(uniqueRooms);
                });
            }
        });

        this.chatSocketService.isDisconnected$.subscribe((isDisconnected: boolean) => {
            if (isDisconnected) {
                window.location.reload();
            }
        });

        amplitude.init(environment.amplitudeApiKey);
    }

    ngOnInit() {
        // const adParams = localStorage.getItem('ad_params');
        // if (adParams) {
        //     const adParamsParsed = adParams.replaceAll('%20', ' ');
        //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
        //     const adParamsObject = JSON.parse(adParamsParsed);
        //     if (
        //         adParamsObject &&
        //         (adParamsObject.af_sub1 != '' || adParamsObject.af_sub3 != '' || adParamsObject.af_sub4 != '')
        //     ) {
        //         if (adParamsObject.c) adParamsObject.campaign = adParamsObject.c;
        //         adParamsObject.click_time = new Date().toISOString();
        //         const conversionData = JSON.stringify(adParamsObject);
        //         this.conversionService.sendConversionData(conversionData).subscribe(res => {
        //             console.log(res.isSaved);
        //         });
        //     }
        // }
    }

    updateConfig(countryCode: string, language: string) {
        this.userService.getConfig(countryCode, language).subscribe((res: ConfigResponse) => {
            if (res.success) {
                fetch(res.config.blockedWordsFileUrl)
                    .then(res => res.text())
                    .then((data: string) => {
                        res.config.blockedWordsArray = [...data.split(',')];
                        this.authService.setConfigSession(res.config);
                    })
                    .then(() => {
                        // this.translocoService.getTranslation(
                        //     this.authService.configSession$.getValue()!.localizationFileUrl,
                        // );
                        this.updateUserDetail();
                        this.chatSocketService.connectSocket();
                    });
            }
        });
    }

    updateUserDetail() {
        this.userService.getCurrentUserDetail().subscribe((data: UserProfileResponse) => {
            if (data.success) {
                this.authService.updateUserDetailSession(data.user);
            }
        });
    }

    updatePartyList(roomIds: number[]) {
        const diffAppend = _.difference(roomIds, this.chatSocketService.activeRoomIds);
        const diffRemove = _.difference(this.chatSocketService.activeRoomIds, roomIds);

        if (this.chatSocketService.bannedRooms.length > 0) {
            this.chatSocketService.bannedRooms.forEach(roomId => {
                const findIndex = this.chatSocketService.onlineRooms.findIndex(room => room.roomId === roomId);
                if (findIndex > -1) {
                    this.chatSocketService.onlineRooms.splice(findIndex, 1);
                }
            });
        }
        if (diffRemove.length > 0) {
            diffRemove.forEach(roomId => {
                const findIndex = this.chatSocketService.onlineRooms.findIndex(room => room.roomId === roomId);
                if (findIndex > -1) {
                    this.chatSocketService.onlineRooms.splice(findIndex, 1);
                }
            });
        }
        diffAppend.forEach(roomId => {
            this.candidateService.getPartyDetail(roomId).subscribe((data: PartyDetailResponse) => {
                if (data.success) {
                    if (!data.room) return;
                    if (this.isAgent && data.room.isPremium) return;
                    this.chatSocketService.onlineRooms.push(data.room);
                }
            });
        });
        this.chatSocketService.activeRoomIds = roomIds;
        this.chatSocketService.onlineRooms = this.chatSocketService.onlineRooms.sort((a, b) =>
            a.priority > b.priority ? -1 : 1,
        );
    }

    @HostBinding('class.dark') get mode() {
        return this.themeService.isDarkMode;
    }
}
