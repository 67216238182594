import {HttpEvent, HttpInterceptorFn, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs';
import userPhotoConverter from '@core/utils/helpers/userPhotoConverter';
import isUserResponse from '@core/utils/helpers/user-response-check';

export const nullPhotoInterceptor: HttpInterceptorFn = (req, next) => {
    return next(req).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // Check if the response body contains 'photo' parameter

                if (isUserResponse(event.body)) {
                    const isOk = event.body.user != null && event.body.user.photo != null;
                    if (isOk) {
                        if (event.body.user.photo === '') {
                            // Parse the 'photo' parameter here
                            const userId = event.body.user.id;
                            const userPhoto = userPhotoConverter(userId);
                            const modifiedBody = {...event.body, user: {...event.body.user, photo: userPhoto}};
                            // Create a new response with the modified body
                            //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            return new HttpResponse({...event, body: modifiedBody});
                        }
                    }
                }
            }
            // If the response body doesn't need modification, return the original event
            return event;
        }),
    );
};
