import {CanMatchFn, Router} from '@angular/router';
import {environment} from '@env/environment';
import {inject} from '@angular/core';

export const prodGuard = (): CanMatchFn => {
    return () => {
        if (!environment.production) return true;
        const router = inject(Router);
        return router.createUrlTree(['/live']);
    };
};
