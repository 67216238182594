import {inject, Injectable} from '@angular/core';
import {Translation, TranslocoLoader} from '@ngneat/transloco';
import {HttpClient} from '@angular/common/http';
import {AppService} from '@core/services/app/app.service';

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
    private http = inject(HttpClient);
    private appService = inject(AppService);

    getTranslation(lang: string) {
        if (this.appService.serverSession$.getValue() !== null) {
            return this.http.get<Translation>(this.appService.localizationFileUrl);
        }
        lang = window.navigator.language.split('-')[0];

        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }
}
